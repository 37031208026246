import designConfig from "@libs/design.config";

export const DEFAULT_LINE_WIDTH = 5;

export const DRAW_LINE_OPACITY = 0.8;
export const EDITOR_COLOR_BLACK = "#000000";
export const EDITOR_COLORS = [
  designConfig.colors.red["500"],
  designConfig.colors.green["500"],
  designConfig.colors.blue["500"],
  designConfig.colors.orange["500"],
  designConfig.colors.yellow["500"],
  designConfig.colors.magenta,
  EDITOR_COLOR_BLACK,
];
