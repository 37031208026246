/**
 * hex to rgb
 * @param {string} color - hex color
 * @param {string} alpha - color alpha value
 * @returns {string} rgb expression
 */
export const getRgb = (color: string, alpha: number | string) => {
  const SHORT_LENGTH = 4;
  const R_INDEX = 3;
  const G_INDEX = 5;
  const B_INDEX = 7;

  const adjustedColor = color.length === SHORT_LENGTH ? `${color}${color.slice(1, SHORT_LENGTH)}` : color;

  const r = Number.parseInt(adjustedColor.slice(1, R_INDEX), 16);
  const g = Number.parseInt(adjustedColor.slice(R_INDEX, G_INDEX), 16);
  const b = Number.parseInt(adjustedColor.slice(G_INDEX, B_INDEX), 16);
  const a = alpha || 1;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
