import { FC } from "react";

import { LoadingOverlay } from "components/UI/LoadingOverlay";

export const GustoLoadingOverlay: FC<{ className?: string; loadingText?: string }> = ({
  className,
  loadingText = "Connecting Archy & Gusto...",
}) => (
  <LoadingOverlay
    overlayClassName={className}
    isLoading
    loadingText={loadingText}
    loadingSubText={
      <>
        This might take 1-2 minutes, please
        <br />
        don&apos;t close this window!
      </>
    }
  />
);
