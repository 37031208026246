import { HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";
import { BoldSpaced } from "@libs/components/UI/BoldSpaced";

const cxStyles = {
  size: {
    sm: "text-sm",
    base: "text-base",
  },
};

interface Props extends HTMLAttributes<HTMLSpanElement> {
  isSelected: boolean;
  disabled?: boolean;
  size?: keyof (typeof cxStyles)["size"];
  paddingY?: string;
}

export const TabText: React.FC<Props> = ({
  isSelected,
  children,
  size = "sm",
  paddingY = "py-3",
  disabled = false,
  ...rest
}) => {
  return (
    <span
      {...rest}
      className={cx(
        "px-4 block text-center relative",
        cxStyles.size[size],
        isSelected && "font-sansSemiBold",
        isSelected && !disabled && "text-primaryTheme",
        disabled ? "text-greyLight" : "hover:text-primaryTheme",
        rest.className
      )}
    >
      <BoldSpaced className={cx("flex items-center", paddingY)}>{children}</BoldSpaced>
      {isSelected ? (
        <span
          className={cx(
            "absolute h-0.5 bottom-0 left-4 right-4",
            disabled ? "bg-greyLight " : "bg-primaryTheme"
          )}
        />
      ) : null}
    </span>
  );
};
