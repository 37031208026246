import { useRef, useEffect } from "react";

export const useOnValueChange = <T>(value: T, onChange?: (newValue: T) => void) => {
  const ref = useRef(value);

  useEffect(() => {
    if (ref.current !== value) {
      ref.current = value;
      onChange?.(value);
    }
  }, [onChange, value]);
};
