import React from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

export const useFamilyFeeCalculationValues = (
  originalValueField: "familyAnnualDeductible" | "familyAnnualMaximum",
  remainingField: "annualDeductibleRemaining" | "annualMaximumRemaining",
  insurance?: PatientInsuranceResponse
) => {
  const originalValue = insurance?.insurancePlan?.[originalValueField];
  const remaining = insurance?.patientInsurance.subscriber[remainingField];

  return React.useMemo(
    () => ({
      originalValue,
      used: isDefined(originalValue) && isDefined(remaining) ? originalValue - remaining : undefined,
      remaining,
    }),
    [originalValue, remaining]
  );
};

export const useIndividualFeeCalculationValues = (
  originalValueField: "individualAnnualMaximum" | "individualAnnualDeductible",
  remainingField: "annualDeductibleRemaining" | "annualMaximumRemaining",
  insurance?: PatientInsuranceResponse
) => {
  const originalValue = insurance?.insurancePlan?.[originalValueField];
  const remaining = insurance?.patientInsurance[remainingField];

  return React.useMemo(
    () => ({
      originalValue,
      used: isDefined(originalValue) && isDefined(remaining) ? originalValue - remaining : undefined,
      remaining,
    }),
    [originalValue, remaining]
  );
};

type InsuranceOrdinal = "PRIMARY" | "SECONDARY" | "OTHER";

export const useOrdinalTitles = (): Record<InsuranceOrdinal, string> =>
  React.useMemo(() => {
    return {
      PRIMARY: "Primary",
      SECONDARY: "Secondary",
      OTHER: "Other",
    };
  }, []);
