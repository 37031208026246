import { useState, useCallback } from "react";
import { useOnValueChange } from "@libs/hooks/useOnValueChange";

export const useIsDirty = <T>(
  value: T,
  options?: { initialValue?: boolean; onDirty?: (newValue: T) => void }
) => {
  const [isDirty, setIsDirty] = useState(options?.initialValue ?? false);

  const markDirty = useCallback(() => setIsDirty(true), []);
  const markClean = useCallback(() => setIsDirty(false), []);

  const onDirty = options?.onDirty;

  const handleValueChange = useCallback(
    (newValue: T) => {
      markDirty();
      onDirty?.(newValue);
    },
    [markDirty, onDirty]
  );

  useOnValueChange(value, handleValueChange);

  return { isDirty, markDirty, markClean };
};
