import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";

export function useWindowSize(debounceTime = SEARCH_DEBOUNCE_DELAY_MS) {
  const [dimensions, setDimensions] = React.useState<{ width: number; height: number } | null>(null);
  const handleResize = useDebouncedCallback(
    () =>
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    debounceTime
  );

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal
      window.removeEventListener("resize", handleResize, { passive: true } as EventListenerOptions);
    };
  }, [handleResize]);

  return dimensions;
}
