import { type fabric } from "fabric";
import { isObject } from "@libs/utils/types";
import { DecimalPlaces, Size, round } from "@libs/utils/math";
import {
  ArchyLine,
  AnglePoint,
  ArchyText,
  ArchyFabricObject,
  DistancePoint,
} from "components/PatientProfile/Imaging/types/fabric";
import { getRgb } from "utils/color";
import { DRAW_LINE_OPACITY } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/constants";

export type ShapeObject = fabric.Object & {
  startPoint: fabric.Point;
  strokeWidth: number;
  origins?: { [s: string]: fabric.Point };
  isRegular: boolean;
};

export type Point = {
  x: number;
  y: number;
};

/**
 * Get the positions of ratated origin by the pointer value
 * @param {{x: number, y: number}} origin - Origin value
 * @param {{x: number, y: number}} pointer - Pointer value
 * @param {number} angle - Rotating angle
 * @returns {Object} Postions of origin
 * @ignore
 */
function getPositionsOfRotatedOrigin(origin: Point, pointer: Point, angle: number) {
  const sx = origin.x;
  const sy = origin.y;
  const px = pointer.x;
  const py = pointer.y;

  const r = (angle * Math.PI) / 180;
  const rx = (px - sx) * Math.cos(r) - (py - sy) * Math.sin(r) + sx;
  const ry = (px - sx) * Math.sin(r) + (py - sy) * Math.cos(r) + sy;

  return {
    originX: sx > rx ? "right" : "left",
    originY: sy > ry ? "bottom" : "top",
  };
}
export const drawWithOpacity = (color: string) => getRgb(color, DRAW_LINE_OPACITY);

const adjustOriginByStartPoint = (pointer: Point, shape: ShapeObject) => {
  const centerPoint = shape.getPointByOrigin("center", "center");

  const angle = -(shape.angle ?? 0);
  const originPositions = getPositionsOfRotatedOrigin(centerPoint, pointer, angle);
  const { originX, originY } = originPositions;
  const origin = shape.getPointByOrigin(originX, originY);
  const left = (shape.left ?? 0) - (centerPoint.x - origin.x);
  const top = (shape.top ?? 0) - (centerPoint.y - origin.y);

  shape.set({
    originX,
    originY,
    left,
    top,
  });

  shape.setCoords();
};

/**
 * Whether the shape has the center origin or not
 * @param {ShapeObject} shape - Shape object
 * @returns {boolean} State
 * @ignore
 */
function hasCenterOrigin(shape: ShapeObject) {
  return shape.originX === "center" && shape.originY === "center";
}

/**
 * Set the start point value to the shape object
 * @param {ShapeObject} shape - Shape object
 * @ignore
 */
function setStartPoint(shape: ShapeObject) {
  const { originX, originY, origins } = shape;

  const originKey = `${originX?.[0] ?? ""}${originY?.[0] ?? ""}`;

  if (origins) {
    shape.startPoint = origins[originKey];
  }
}

const DIVISOR = {
  rect: 1,
  ellipse: 2,
  triangle: 1,
};
const DIMENSION_KEYS = {
  rect: {
    w: "width",
    h: "height",
  },
  ellipse: {
    w: "rx",
    h: "ry",
  },
  triangle: {
    w: "width",
    h: "height",
  },
};

/**
 * Adjust the dimension of shape on firing mouse move event
 * @param {{x: number, y: number}} pointer - Pointer value
 * @param {ShapeObject} shape - Shape object
 * @ignore
 */
function adjustDimensionOnMouseMove(pointer: Point, shape: ShapeObject) {
  const { type, strokeWidth, startPoint: origin, isRegular } = shape;
  const shapeType = type as "ellipse" | "rect" | "triangle";
  const divisor = DIVISOR[shapeType];
  const dimensionKeys = DIMENSION_KEYS[shapeType];
  const isTriangle = Boolean(shapeType === "triangle");
  let width = Math.abs(origin.x - pointer.x) / divisor;
  let height = Math.abs(origin.y - pointer.y) / divisor;

  if (width > strokeWidth) {
    width -= strokeWidth / divisor;
  }

  if (height > strokeWidth) {
    height -= strokeWidth / divisor;
  }

  if (isRegular) {
    width = height = Math.max(width, height);

    if (isTriangle) {
      height = (Math.sqrt(3) / 2) * width;
    }
  }

  shape.set({
    [dimensionKeys.w]: width,
    [dimensionKeys.h]: height,
  });
}

/**
 * Adjust the origin of shape by the moving pointer value
 * @param {{x: number, y: number}} pointer - Pointer value
 * @param {fabric.Object} shape - Shape object
 * @ignore
 */
function adjustOriginByMovingPointer(pointer: Point, shape: ShapeObject) {
  const origin = shape.startPoint;
  const angle = -(shape.angle ?? 0);
  const originPositions = getPositionsOfRotatedOrigin(origin, pointer, angle);
  const { originX, originY } = originPositions;

  shape.setPositionByOrigin(origin, originX, originY);
  shape.setCoords();
}

/**
 * Resize the shape
 * @param {ShapeObject} shape - Shape object
 * @param {{x: number, y: number}} pointer - Mouse pointer values on canvas
 * @param {boolean} isScaling - Whether the resizing action is scaling or not
 */
export const resize = (shape: ShapeObject, pointer: Point) => {
  if (hasCenterOrigin(shape)) {
    adjustOriginByStartPoint(pointer, shape);
    setStartPoint(shape);
  }

  adjustDimensionOnMouseMove(pointer, shape);

  adjustOriginByMovingPointer(pointer, shape);
};

export const isArchyObject = (val: fabric.Object): val is ArchyFabricObject => {
  const data = isObject(val.data) ? (val.data as { tool?: string }) : undefined;

  return Boolean(data?.tool);
};
export const isAngleTool = (val: fabric.Object): val is AnglePoint | ArchyLine | ArchyText => {
  // The only archy object currently is the angle tool
  return isArchyObject(val) && val.data.tool === "angle";
};

export const isDistanceTool = (val: fabric.Object): val is DistancePoint | ArchyLine => {
  // The only archy object currently is the angle tool
  return isArchyObject(val) && val.data.tool === "distance";
};

export const itemsInGroup = <T = ArchyFabricObject>(objects: fabric.Object[], groupId: string) => {
  return objects.filter((obj) =>
    isAngleTool(obj) || isDistanceTool(obj) ? obj.data.groupId === groupId : false
  ) as T[];
};

export const isText = (val: fabric.Object): val is fabric.Text => {
  return val.type === "text";
};

export const isGroup = (val: fabric.Object): val is fabric.Group => {
  return val.type === "group";
};

const doLinesIntersect = (line1: Line, line2: Line) => {
  const { x1: x1, y1: y1 } = line1;
  const { x2: x2, y2: y2 } = line1;
  const { x1: x3, y1: y3 } = line2;
  const { x2: x4, y2: y4 } = line2;

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  if (denominator === 0) {
    return false;
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
};

type Line = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const lineIntersectsWithRect = (
  line: Line,
  rect: { top: number; left: number; width: number; height: number }
): boolean => {
  const leftLine: Line = { x1: rect.left, y1: rect.top, x2: rect.left, y2: rect.top + rect.height };
  const rightLine: Line = {
    x1: rect.left + rect.width,
    y1: rect.top,
    x2: rect.left + rect.width,
    y2: rect.top + rect.height,
  };
  const topLine: Line = { x1: rect.left, y1: rect.top, x2: rect.left + rect.width, y2: rect.top };
  const bottomLine: Line = {
    x1: rect.left,
    y1: rect.top + rect.height,
    x2: rect.left + rect.width,
    y2: rect.top + rect.height,
  };

  return (
    doLinesIntersect(line, leftLine) ||
    doLinesIntersect(line, rightLine) ||
    doLinesIntersect(line, topLine) ||
    doLinesIntersect(line, bottomLine)
  );
};

export const getAngle = (line2: ArchyLine, line1: ArchyLine) => {
  const angle1 = Math.atan2(line1.y1 - line1.y2, line1.x1 - line1.x2);
  const angle2 = Math.atan2(line2.y1 - line2.y2, line2.x1 - line2.x2);
  const THREE_SIXTY = 360;
  const ONE_EIGHTY = 180;
  let angle = angle1 - angle2;

  angle = Math.abs((angle * ONE_EIGHTY) / Math.PI);

  if (THREE_SIXTY - angle < angle) {
    angle = THREE_SIXTY - angle;
  }

  return ONE_EIGHTY - angle;
};

export const findObject = (
  objects: fabric.Object[],
  comparisonFunction: (currentObj: fabric.Object) => boolean
): fabric.Object | undefined => {
  for (const obj of objects) {
    if (comparisonFunction(obj)) {
      return obj;
    } else if (isGroup(obj)) {
      const foundObj = findObject(obj.getObjects(), comparisonFunction);

      if (foundObj) {
        return foundObj;
      }
    }
  }

  return undefined;
};

export const OFFSCREEN_RENDER_ORIGIN: React.CSSProperties = {
  position: "absolute",
  left: -10_000,
  top: -10_000,
};

export const isAspectCompatible = (size: Size, container: Size) => {
  const aspect = round(container.width / container.height, DecimalPlaces.hundredth);
  const innerAspect = size.width / size.height;

  return !((innerAspect < 1 && aspect >= 1) || (innerAspect >= 1 && aspect < 1));
};
