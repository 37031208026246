import { UpdateUserMfaRequest, IssueTokenRequest, IssueSupportTokenRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

const getProfileImageQueryKeysToInvalidate = (userId: number, practiceId: number) => [
  [getQueryKey("practices", "getPatientSummary"), { patientId: userId, practiceId }],
  [getQueryKey("practices", "getPatient"), { patientId: userId, practiceId }],
  [getQueryKey("v2", "getFamilyMembersV2")],
  [getQueryKey("practices", "getPatients")],
  [getQueryKey("practices", "lookupPatient")],
  [getQueryKey("practices", "lookupPatientSummary")],
  [getQueryKey("practices", "getProfileImage"), { userId, practiceId }],
  [getQueryKey("practices", "getEmployee"), { employeeId: userId, practiceId }],
  [getQueryKey("practices", "getEmployees"), { practiceId }],
];

export const deleteProfileImage = makeMutation({
  mutationKey: ["practices", "deleteProfileImage"],
  formatParams: (args: { userId: number; practiceId: number }) => [args.userId, args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const uploadProfileImage = makeMutation({
  mutationKey: ["practices", "uploadProfileImage"],
  formatParams: (args: { userId: number; practiceId: number; image: File }) => [
    args.userId,
    args.practiceId,
    { image: args.image },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const updateUserMfa = makeMutation({
  mutationKey: ["user", "updateUserMfa"],
  formatParams: (args: { data: UpdateUserMfaRequest }) => [args.data],
});

export const issueAccountToken = makeMutation({
  mutationKey: ["user", "issueAccountToken"],
  formatParams: (args: IssueTokenRequest) => [args],
});

export const issueSupportToken = makeMutation({
  mutationKey: ["user", "issueSupportToken"],
  formatParams: (args: IssueSupportTokenRequest) => [args],
});
