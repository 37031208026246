import { Blocker, useBlocker } from "react-router-dom";
import { useEffect, useRef } from "react";
import { getFullUrl } from "@libs/utils/location";

const isSameBlocker = (blockerA: Blocker, blockerB: Blocker) => {
  return (
    blockerA.state === blockerB.state &&
    ((!blockerA.location && !blockerB.location) ||
      (blockerA.location &&
        blockerB.location &&
        getFullUrl(blockerA.location) === getFullUrl(blockerB.location)))
  );
};

/**
 * Blocks route changes until the handler resolves. Useful for blocking
 * navigations until things like auto save completes.
 * @param handler Handler function that must resolve for the navigation to succeed.
 */
export const useBlockRouteUntil = (handler: () => Promise<void>) => {
  const blocker = useBlocker((args) => getFullUrl(args.currentLocation) !== getFullUrl(args.nextLocation));
  const blockerRef = useRef(blocker);
  const handlerRef = useRef(handler);

  // every
  useEffect(() => {
    blockerRef.current = blocker;
    handlerRef.current = handler;

    if (blocker.state === "blocked") {
      handler()
        .then(() => {
          // check to make sure neither the blocker nor the handler have changed
          // if they have a new promise will be created and we should rely on that
          // to know whether to reset or proceed.
          if (isSameBlocker(blockerRef.current, blocker) && handlerRef.current === handler) {
            blocker.proceed();
          }
        })
        .catch(() => {
          if (isSameBlocker(blockerRef.current, blocker) && handlerRef.current === handler) {
            blocker.reset();
          }
        });
    }
  }, [handler, blocker]);
};
